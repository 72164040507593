import React from "react";
import styled from "styled-components";
import image from "../assets/profile.png";
import Cvc from "./Cvc";
import SocialMediaIcons from "./SocialMediaIcons";
import { useTranslation } from "react-i18next";

// Styled components
const ContainerProfile = styled.div`
    width: 100%;
    height: calc(100vh - 90px);
    background: linear-gradient(to bottom, var(--s-bg-c), var(--p-bg-c));
    color: var(--p-txt-c);
    position: relative;
    padding: 0 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ContentProfile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

const ImgContent = styled.img`
    width: 150px;
    border-radius: 50%;
`;

const Title = styled.h2`
    font-size: calc(1.325rem + .9vw);
`;

const SubTitle = styled.p`
    font-weight: 600;
`;

const Paragraph = styled.p`
    font-size: 12px;
    font-weight: 500;
    max-width: 400px;
    margin-top: 5px;
    text-align: justify;
    @media (max-width: 640px){
        text-align: center;
    }
`;
const SubjectContainer = styled.div`display:block;
    @media (max-width: 640px){
        text-align: center;
    }
`

const Profile = () => {
    const {t} = useTranslation('global');

    return (
        <ContainerProfile>
            <ContentProfile className='jaBtiH'>
                <ImgContent src={image} alt="Profile"/>
                <SubjectContainer>
                    <Title>{t("home.fullname")}</Title>
                    <SubTitle>{t("home.domaine")}</SubTitle>
                    <Paragraph>{t("home.paragraph")}</Paragraph>
                </SubjectContainer>
            </ContentProfile>
            <Cvc />
            <SocialMediaIcons />
        </ContainerProfile>
    );
}

export default Profile;
