import React from 'react';
import { get, ref } from 'firebase/database';
import { database } from '../Config/firebase'; 
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LanguageSelect = styled.select`
  position: inherit;
  font-size: 0.9rem;
  padding: 2px 5px;
  background: var(--p-c);
  border: none;
  color: #ccc;
  width: 55px;
  height: 24px;
  transform: skewX(-7deg);
`;

const ChangeLanguageButton = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang) => {
    localStorage.setItem('selectedLanguage', lang); // Save to localStorage

    // Dynamically fetch translations and change language
    fetchTranslations(lang).then((data) => {
      i18n.addResourceBundle(lang, 'global', data, true, true);
      i18n.changeLanguage(lang);
      document.body.dir = i18n.dir(lang);
    });
  };
  const fetchTranslations = async (lang) => {
    const dbRef = ref(database, `translations/${lang}`);
    const snapshot = await get(dbRef);
    return snapshot.exists() ? snapshot.val() : {};
  };

  return (
    <LanguageSelect value={i18n.language} onChange={(e) => handleChangeLanguage(e.target.value)}>
      <option value="en">En</option>
      <option value="fr">Fr</option>
      <option value="ar">Ar</option>
    </LanguageSelect>
  );
};

export default ChangeLanguageButton;
