import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';


const MenuContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  display: none;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
  css`
    display: block;
  `}
`

const Menu = ({ isMenuOpen }) => {
  const { t } = useTranslation('global');
  const [hoverQuad, updateQuad] = React.useState("");
  const [activeQuad, updateActive] = React.useState("");
  const navigate = useNavigate();

  let targetPage;
  const handleClick = quad => {
    if (quad === activeQuad) {
      updateActive("");
    } else {
      updateActive(quad);
      const map = {
        'colA': 'about',
        'colB': 'blog',
        'colC': 'portfolio',
        'colD': 'contact',
      };
      if (quad in map) {
        targetPage = map[quad];
        setTimeout(() => {
          navigate('../'+targetPage);
        }, "400");
      } else {
        console.warn(`Invalid quad value: ${quad}`);
      }
    }
  };

  const handleExit = () => {
    setTimeout(updateQuad(""), 300);
  };
  let rowA = "row ";
  let rowB = "row ";
  let colA = "column bg1 ";
  let colB = "column bg2 ";
  let colC = "column bg3 ";
  let colD = "column bg4 ";

  // it's kind of ugly but effective
  // just swapping some classnames
  // based on active or just hover
  // css flex does the rest

  if (activeQuad === "") {
    switch (hoverQuad) {
      case "colA":
        rowA += "onHover";
        rowB += "offHover";
        colA += "onHover";
        colC += "onHover";
        colB += "offHover";
        colD += "offHover";
        break;

      case "colB":
        rowA += "onHover";
        rowB += "offHover";
        colB += "onHover";
        colD += "onHover";
        colA += "offHover";
        colC += "offHover";
        break;

      case "colC":
        rowA += "offHover";
        rowB += "onHover";
        colC += "onHover";
        colA += "onHover";
        colB += "offHover";
        colD += "offHover";
        break;

      case "colD":
        rowA += "offHover";
        rowB += "onHover";
        colD += "onHover";
        colB += "onHover";
        colA += "offHover";
        colC += "offHover";
        break;
      default: break;
    }
  } else {
    switch (activeQuad) {
      case "colA":
        rowA += "active";
        rowB += "off";
        colA += "show";
        colC += "show";
        colB += "hide";
        colD += "hide";
        break;

      case "colB":
        rowA += "active";
        rowB += "off";
        colB += "show";
        colD += "show";
        colA += "hide";
        colC += "hide";
        break;

      case "colC":
        rowA += "off";
        rowB += "active";
        colA += "show";
        colC += "show";
        colB += "hide";
        colD += "hide";
        break;

      case "colD":
        rowA += "off";
        rowB += "active";
        colB += "show";
        colD += "show";
        colA += "hide";
        colC += "hide";
        break;
      default: break;
    }
  }
  return (
    <MenuContainer isMenuOpen={isMenuOpen}>
      {/* <div > */}

      <div className={rowA}>
        <div
          className={colA}
          onMouseEnter={() => {
            updateQuad("colA");
          }}
          onMouseLeave={() => {
            handleExit();
          }}
        >
          <div
            role="button"
            className="ct colA"
            onClick={() => {
              handleClick("colA");
            }}
          >
            <label>{t("about.page")}</label>
          </div>
        </div>

        <div
          className={colB}
          onMouseEnter={() => {
            updateQuad("colB");
          }}
          onMouseLeave={() => {
            handleExit();
          }}
        >
          <div
            role="button"
            className="ct colB"
            onClick={() => {
              handleClick("colB");
            }}
          >
            <label>{t("blog.page")}</label>
          </div>
        </div>
      </div>

      <div className={rowB}>
        <div
          className={colC}
          onMouseEnter={() => {
            updateQuad("colC");
          }}
          onMouseLeave={() => {
            handleExit();
          }}
        >
          <div
            role="button"
            className="ct colC"
            onClick={() => {
              handleClick("colC");
            }}
          >
            <label>{t("portfolio.page")}</label>
          </div>
        </div>

        <div
          className={colD}
          onMouseEnter={() => {
            updateQuad("colD");
          }}
          onMouseLeave={() => {
            handleExit();
          }}
        >
          <div
            role="button"
            className="ct colD"
            onClick={() => {
              handleClick("colD");
            }}
          >
            <label>{t("contact.page")}</label>
          </div>
        </div>
      </div>
    </MenuContainer>
  );
};

export default Menu