import './index.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { database } from './Config/firebase';
import { get, ref } from 'firebase/database';

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

const fetchTranslations = async (lang) => {
  const dbRef = ref(database, `translations/${lang}`);
  const snapshot = await get(dbRef);
  return snapshot.exists() ? snapshot.val() : {};
};

const initI18n = async (lang) => {
  const resources = {};
  const translationData = await fetchTranslations(lang);
  resources[lang] = { global: translationData };

  await i18next.init({
    interpolation: { escapeValue: false },
    lng: lang,
    resources,
  });
};

const AppWrapper = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [dir] = useState(selectedLanguage === 'ar' ? 'rtl' : 'ltr');

  useEffect(() => {
    const initializeApp = async () => {
      await initI18n(selectedLanguage);
      setIsInitialized(true);
    };

    initializeApp();
  }, []);

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  return (
    isInitialized && (
      <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </React.StrictMode>
    )
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<AppWrapper />);

reportWebVitals();
