// Blog.js
import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import BlogList from '../Components/BlogList';
import BlogPost from '../Components/BlogPost';
import Footer from '../Components/Footer';

const BlogPage = styled.div`
  width: 100%;
  height:calc(100dvh - 60px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--p-bg-c); 
  }

  &::-webkit-scrollbar-thumb {
    border-radius:5px;
    background: var(--p-c); 
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--s-c); 
  }
`;

const Blog = () => {
  return (
    <>
      <BlogPage>
        <Routes>
          <Route path="/" element={<BlogList />} />
          <Route path="/:id" element={<BlogPost />} />
        </Routes>
        <Footer/>
      </BlogPage>
    </>
  );
};

export default Blog;