import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Styled Components
const BlogListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  margin-bottom: 1.5rem;
  justify-items: center;
`;

const BlogCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;
const BlogImageContainer = styled.div`
  overflow: hidden;
  border-radius: 5px;
`
const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.2);
`;

const BlogTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--p-c);
`;

const BlogExcerpt = styled.p`
  font-size: 1rem;
  color: var(--s-txt-c);
`;

const BlogList = () => {
  const {t} = useTranslation('global');
  return (
    <BlogListContainer>
      {t('blog.posts', { returnObjects: true }).map((post) => (
        <BlogCard key={post.id}>
          <Link to={`/blog/${post.id}`}>
            <BlogImageContainer>
              <BlogImage src={post.featured_image} alt={post.title} />
            </BlogImageContainer>
            <BlogTitle>{post.title}</BlogTitle>
            <BlogExcerpt>{post.excerpt}</BlogExcerpt>
          </Link>
        </BlogCard>
      ))}
    </BlogListContainer>
  );
};

export default BlogList;
