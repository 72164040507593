import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DarkModeProvider } from "./Components/DarkModeContext";
import Home from "./Pages/Home";
import Content from "./Components/Content";
import NoPage from "./Pages/NoFind";
import FakeAlerts from "./Components/FakeAlerts"
import Project from './Components/Projects';
import PdfGenerator from './Components/PdfResume';
import LoginModal from './Components/Login';

const App = () => {
  return (
    <DarkModeProvider>
      <LoginModal/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Content page="about" />} />
          <Route path="/blog" element={<Content page="blog" />} >
            <Route path="/blog/:link" element={<Content page="blog" /> }/>
          </Route>
          <Route path="/portfolio" element={<Content page="portfolio" />} >
            <Route path='/portfolio/:link' element={<Content page="portfolio"/>}/>
          </Route>
          <Route path="/contact" element={<Content page="contact" />} />
          <Route path='/extension/fakealerts' element={<FakeAlerts />}/>
          <Route path='/project' element={<Project />}/>
          <Route path='/resume' element={<PdfGenerator/>}/>
          <Route path='*' element={<NoPage />}/>
        </Routes>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
