import React from 'react'
// import Logo from './Logo'
import styled from 'styled-components'

const Header = styled.div`
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    z-index: 2;
`

const header = ({children}) => {
    return (
        <Header>
            {children}
        </Header>
    )
}

export default header
