import React from 'react'
import styled from 'styled-components'
import { Routes, Route } from 'react-router-dom';
import PortfolioList from '../Components/PortfolioList';
import Projects from '../Components/Projects';
import Footer from '../Components/Footer';


const PortfolioPage = styled.div`
  width: 100%;
  height:calc(100dvh - 60px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--p-bg-c); 
  }

  &::-webkit-scrollbar-thumb {
    border-radius:5px;
    background: var(--p-c); 
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--s-c); 
  }
`

const Portfolio = () => {
  return (
    <PortfolioPage>
      <Routes>
        <Route path="/" element={<PortfolioList />} />
        <Route path="/:link" element={<Projects />} />
      </Routes>
      <Footer/>
    </PortfolioPage>
  )
}

export default Portfolio