import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import styled from 'styled-components';

const ContainerLogo = styled.div`
    position: absolute;
    top:0;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(0, 40dvh) scale(4);
    @media (max-hieght: 650px){
        transform: translate(0, 40dvh) scale(3);
    }
`;

const Logo = styled.svg`
    width: 50px;
    margin-top: .5em;
`;

const LogoColor = styled.path`
    fill: var(--p-c);
`;

const LogoText = styled.div`
    font-family: "Noto Sans Tifinagh", sans-serif;
    font-size: 20px;
    margin-top: .5em;
    opacity: 1;
    display: block;
    color: var(--p-c);
`;

const HomePage = () => {
  const logoBoxRef = useRef(null);
  const logoTextRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollDirection = scrollPosition > 0 ? 'scroll-down' : 'scroll-up';
      gsap.to('.isLogO', { cursor: scrollDirection === 'scroll-down' ? 'pointer' : 'auto', duration: 0.3 });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(logoBoxRef.current, {
      scrollTrigger: {
        trigger: '.jaBtiH',
        start: '0 100%',
        endTrigger: '.jaBtiH',
        end: '0 50%',
        scrub: true,
        refreshPriority: 3
      },
      y: 0,
      scale: 1
    });

    gsap.to(logoTextRef.current, {
      scrollTrigger: {
        trigger: '.jaBtiH',
        start: '0 100%',
        endTrigger: '.jaBtiH',
        end: '0 50%',
        scrub: true
      },
      opacity: 0,
      display: 'none'
    });
  }, []);

  return (
    <ContainerLogo className='isLogO' ref={logoBoxRef} onClick={scrollTop}>
      <Logo viewBox="0 0 657.49 723.66">
        <LogoColor d="M316.15,363.38a84.35,84.35,0,0,1-24.59-52.88l158.94,159L492.93,427,270.25,204.34a145,145,0,0,0,3.48,201.47L393.93,526l42.43-42.42Z" transform="translate(-171.26 -138.17)" />
        <LogoColor d="M726.27,660.36l-120.2-120.2-42.43,42.42L683.85,702.79a84.35,84.35,0,0,1,24.59,52.88L549.5,596.73l-42.43,42.42L729.75,861.83a145,145,0,0,0-3.48-201.47Z" transform="translate(-171.26 -138.17)" />
        <LogoColor d="M490,283.09a84.36,84.36,0,0,1,20-54.79V410l60-60V138.17A145,145,0,0,0,430,283.09V350l60,60Z" transform="translate(-171.26 -138.17)" />
        <LogoColor d="M729.81,264.34a144,144,0,0,0-102.53,42.47l-456,456a145,145,0,0,0,201.46-3.48l456-456A144,144,0,0,0,729.81,264.34ZM330.29,716.93a84.36,84.36,0,0,1-52.88,24.6l392.3-392.29a84.36,84.36,0,0,1,52.88-24.6Z" transform="translate(-171.26 -138.17)" />
      </Logo>
      <LogoText ref={logoTextRef}>ⵅⴰⵉⵎⴻⴷ</LogoText>
    </ContainerLogo>
  );
}

export default HomePage;
