import React from 'react';
import styled, { keyframes } from 'styled-components';


const scaleIn = keyframes`
    from {
        transform: scale(0, 0);
        opacity: .5;
    }
    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
`;

const animationOne = keyframes`
    0%{transform: rotate(0) translate(0,-40px); opacity: 0;}
    10%{transform: translate(0,0); opacity: 1;}
    39%{transform: rotate(0)}
    50%{transform: rotate(405deg);}
    100%{transform: rotate(405deg);}
`;

const animationTwo = keyframes`
    0%{transform: rotate(0);opacity: 0;}
    19%{opacity: 0;}
    20%{opacity: 1;}
    39%{transform: rotate(0);}
    50%{transform: rotate(495deg);}
    100%{transform: rotate(495deg);}
`;

const animationThree = keyframes`
    0%{transform: rotate(0);opacity: 0;}
    19%{opacity: 0;}
    20%{opacity: 1;}
    39%{transform: rotate(0);}
    50%{transform: rotate(360deg);}
    100%{transform: rotate(360deg);}
`;

const PreloaderContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: var(--p-bg-c);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
    visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
    transition: all .5s ease-in-out;
`;

const Loading = styled.div`
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    scale: 0.5;
`;

const Circle = styled.div`
    border-radius: 50%;
    background-color: var(--p-c);
    width: 200px;
    height: 200px;
    position: absolute;
    opacity: 0;
    animation: ${scaleIn} 3s infinite cubic-bezier(.36, .11, .89, .32);
`;

const Element = styled.div`
    position: absolute;
    border-start-start-radius: 40px;
    border-end-end-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FirstElement = styled(Element)`
    width: 40px;
    height: 200px;
    animation: ${animationThree} 1.5s ease-in-out;
    &::after{
        content: "";
        width: 10px;
        height: 75px;
        top: 25px;
        border-start-start-radius: 40px;
        background-color: var(--p-bg-c);
        position: absolute;
    }
    &::before{
        content: "";
        width: 30px;
        height: 90px;
        top: 0;
        border-start-start-radius: 40px;
        background-color: var(--p-c);
        border: 4px solid  var(--p-bg-c);
        position: absolute;
    }
`;

const SecondElement = styled(Element)`
    width: 40px;
    height: 200px;
    background-color: var(--p-c);
    border: 4px solid var(--p-bg-c);
    transform: rotate(495deg);
    animation: ${animationTwo} 1.5s ease-in-out;
    &::after{
        content: "";
        width: 10px;
        height: 150px;
        border-start-start-radius: 40px;
        background-color:  var(--p-bg-c);
        border-start-start-radius: 40px;
        border-end-end-radius: 40px;
    }
`;

const ThirdElement = styled(Element)`
    width: 40px;
    height: 200px;
    background-color: var(--p-c);
    border: 4px solid var(--p-bg-c);
    transform: rotate(405deg);
    animation: ${animationOne} 1.5s ease-in-out;
    &::after{
        content: "";
        width: 10px;
        height: 150px;
        border-start-start-radius: 40px;
        background-color: var(--p-bg-c);
        border-start-start-radius: 40px;
        border-end-end-radius: 40px;
    }
`;

const Preloader = ({ isLoading }) => {

    return (
        <PreloaderContainer isLoading={isLoading}>
            <Loading>
                <Circle style={{ animationDelay: '-3s', animationDuration: '3s' }} />
                <Circle style={{ animationDelay: '-2s', animationDuration: '3s' }} />
                <Circle style={{ animationDelay: '-1s', animationDuration: '3s' }} />
                <Circle style={{ animationDelay: '0s', animationDuration: '3s' }} />
                <FirstElement />
                <SecondElement />
                <ThirdElement />
            </Loading>
        </PreloaderContainer>
    );
};

export default Preloader;
