import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Preloader from '../Components/Preloader';
import Header from '../Components/Header';
import DarkMode from '../Components/DarkMode';
import Menu from '../Components/Menu';
import GoMenu from '../Components/GoMenu';
import ChangeLanguageButton from '../Components/ChangeLanguageButton';
import Brand from '../Components/Brand';
import BackgroundAnimate from '../Components/BackgroundAnimation';
import Profile from '../Components/Profile';
import Footer from '../Components/Footer';
import { TabTitle } from '../Config/TitleName';
import { useTranslation } from 'react-i18next';

const HeaderLeft = styled.div`
  display: flex;
  width: 150px;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      display: none;
    `}
`;

const HeaderCenter = styled.div`
  display: flex;
  width: 60px;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      display: none;
    `}
`;

const HeaderRight = styled.div`
  display: flex;
  width: 150px;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const Home = () => {
  const { t } = useTranslation('global');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(() => {
    // Check if the user has visited before from session storage
    const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
    return !hasVisitedBefore; // If not visited before, set to true, otherwise false
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (!isTouchDevice) {
      const handleScroll = (event) => {
        const { deltaY } = event;

        if (deltaY > 0) {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };

      const handleKeyPress = (event) => {
        const { key } = event;

        if (key === 'ArrowUp') {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (key === 'ArrowDown') {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
      };

      window.addEventListener('wheel', handleScroll);
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('wheel', handleScroll);
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, []);

  useEffect(() => {
    const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
        
    if (!hasVisitedBefore) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
        sessionStorage.setItem('hasVisitedBefore', 'true');
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, []);

  TabTitle(t("home.page"));

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }

  return (
    <>
      <Menu isMenuOpen={isMenuOpen} />
      <Header>
        <HeaderRight>
          <GoMenu isMenuOpen={isMenuOpen} onClick={toggleMenu} />
        </HeaderRight>
        <HeaderCenter isMenuOpen={isMenuOpen}>
          <Brand />
        </HeaderCenter>
        <HeaderLeft isMenuOpen={isMenuOpen}>
          <DarkMode />
          <ChangeLanguageButton />
        </HeaderLeft>
      </Header>
      <BackgroundAnimate />
      <Profile />
      <Footer />
    </>
  );
};

export default Home;
