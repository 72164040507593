import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

const ModalContent = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
`;

const NavigationButton = styled.button`
  background-color: transparent;
  color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  width: 50%;
  height: calc(100% - 40px);
  right: 0;
  font-size: xxx-large;

  &:nth-child(2) {
    left: 0;
  }


  &:active {
    background-color: var(--s-c-transparent);
    color: var(--p-txt-c);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 40px;
  height: 40px;
  margin: 10px;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  span {
    position: relative;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: transparent;
    margin: auto;
    transition: all 0.2s ease-in-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    left: 5px;
    border-radius: 2px;
    background-color: #1888c8;
    transition: all 0.4s ease-in-out;
  }

  &:before {
    top: 18px;
    transform: rotate(-45deg);
  }

  &:after {
    bottom: 18px;
    transform: rotate(45deg);
  }
`;
const ReactModal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--p-bg-c);
`

const Slideshow = ({ images, isOpen, onClose, startIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  // Update the state when the modal is about to open
  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(startIndex);
    }
  }, [isOpen, startIndex]);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Slideshow"
      ariaHideApp={false}
    >
      <ModalContent>
        <ModalImage src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        <NavigationButton onClick={handlePrev}>{"<"}</NavigationButton>
        <NavigationButton onClick={handleNext}>{">"}</NavigationButton>
        <CloseButton onClick={onClose}><span /></CloseButton>
      </ModalContent>
    </ReactModal>
  );
};

export default Slideshow;
