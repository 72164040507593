import React from 'react'
import styled from 'styled-components';
import Palestine from '../assets/Palestine.png'

const currentDate = new Date();
const FooterPage = styled.div`
    color: var(--p-txt-c);
    background-color: var(--p-bg-c);
    width: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .5em 0;

    img{
        height: 50px;
        padding: .5em 0;
    }
`

const Footer = () => {
    return (
        <FooterPage id='footer'><img src={Palestine} alt="Palestine"/> { currentDate.getFullYear() } © KhAiMed</FooterPage>
    )
}

export default Footer