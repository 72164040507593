import React , { useRef,useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';

const ContactPage = styled.div`
  width: 100%;
  height:calc(100dvh - 60px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: var(--p-bg-c); 
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius:5px;
    background: var(--p-c); 
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: var(--s-c); 
  }
`
const Container = styled.section`
  margin: 6rem 0;
`

const ContactContainer = styled.div`
  width: 58%;
  height: 100%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
  align-content: center;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
const ContactTitle = styled.h2`
  text-align: center;
  margin-bottom: 2em;
  color:var(--p-txt-c);
`

const ContactOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const ContactOption = styled.article`
  background: var(--t-bg-c);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  word-break: break-all;
  color :var(--p-txt-c);

  &:hover {
    background: transparent;
    border-color: var(--p-txt-c);
  }
`;

const OptionIcon = styled.i`
  color:var(--p-c);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const OptionLink = styled.a`
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color:var(--p-c);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--p-txt-c);
  resize: none;
  color: var(--p-txt-c);
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--p-txt-c);
  resize: none;
  color: var(--p-txt-c);
`;

const Button = styled.button`
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background-color: var(--p-c);
  color: var(--p-txt-c);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--s-txt-c);
    color: var(--p-bg-c);
  }
`;
const MessageSend = styled.p`
  color: var(--p-txt-c);
`
const Contact = () => {
  const { t } = useTranslation('global');
  const form = useRef();
  const messageRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm('service_6lamvug', 'template_s367l1m', form.current, '4x_V095wBvb-URmK9')
      .then(
        () => {
          messageRef.current.innerText = t('contact.message');
          e.target.reset();
          setIsSending(false);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsSending(false);
        }
      );
  };

  return (
    <ContactPage>
      <Container>
        <ContactTitle>{t('contact.text')}</ContactTitle>
        <ContactContainer>
          <ContactOptions>
            {t('contact.options', { returnObjects: true }).map((option, index) => (
              <ContactOption key={index}>
                <OptionIcon className={`fa fa-solid ${option.icon}`} />
                <h4>{option.title}</h4>
                <h5>{option.address}</h5>
                <OptionLink href={option.link}>{option.text}</OptionLink>
              </ContactOption>
            ))}
          </ContactOptions>

          <Form ref={form} onSubmit={sendEmail}>
            <Input type="text" name="from_name" placeholder={t('contact.form.fields.0')} required />
            <Input type="email" name="from_email" placeholder={t('contact.form.fields.1')} required />
            <TextArea name="message" rows="7" placeholder={t('contact.form.fields.2')} required />
            <Button type="submit" disabled={isSending}>{t('contact.form.fields.3')}</Button>
            <MessageSend ref={messageRef} />
          </Form>
        </ContactContainer>
      </Container>
      <Footer/>
    </ContactPage>
  );
};



export default Contact;