
import React, { useContext } from 'react';
import styled from 'styled-components';
import DarkModeContext from './DarkModeContext';

const Switch = styled.label`
  position: relative;
  width: 50px;
  height: 24px;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--p-c);
  transform: skewX(-7deg);
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #ccc;
    transition: .4s;
  }

  ${Input}:checked + & {
    background-color: #ccc;

    &:before {
      background-color: var(--p-c);
      transform: translateX(26px);
    }
  }
`;

const DarkMode = () => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const selectedTheme = localStorage.getItem('selectedTheme');
  const toggleTheme = () => {
    toggleDarkMode();
    console.log('Toggled Theme:', isDarkMode); // Add for debugging
  };

  return (
    <Switch>
      <Input
        type="checkbox"
        onChange={toggleTheme}
        checked={isDarkMode}
        defaultChecked={selectedTheme === "dark"}
      />
      <Slider/>
    </Switch>
  );
}

export default DarkMode;
