import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const BackButton = styled.button`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  margin: 10px;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    content: '';
    left: 5px;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: var(--p-c);
    align-self: flex-end;
  }

  &:before {
    top: 10.5px;
    transform: rotate(-35deg);
  }

  &:after {
    bottom: 10.5px;
    transform: rotate(35deg);
  }
`;

const GoBack = () => {
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (isClicked) return;
    setIsClicked(true);

    const path = location.pathname;
    const pathSegments = path.split('/').filter(Boolean);

    if (pathSegments.length > 1) {
      pathSegments.pop();
      const newPath = `/${pathSegments.join('/')}`;
      navigate(newPath);
    } else {
      navigate('/');
    }

    setTimeout(() => {
      const menu = document.querySelector("#root > div > div > button");
      if (menu) menu.click();
      setIsClicked(false);
    }, 20);
  };

  return (
    <BackButton onClick={handleClick}>
      <span />
    </BackButton>
  );
};

export default GoBack;
