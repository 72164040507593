import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ImageProfil from '../assets/profile.png';

// Styled Components
const BlogPostContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
  color: var(--p-txt-c);
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const AuthorName = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: var(--p-txt-c);
`;

const AuthorBio = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

const ContentSection = styled.div`
  margin-bottom: 20px;
  color: var(--p-txt-c);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const Categories = styled.div`
  margin-bottom: 10px;
  color: var(--p-txt-c);
  strong{
  display:block;
  }
`;

const Category = styled.span`
  background-color: var(--t-bg-c);
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 0.9rem;
`;

const Tags = styled.div`
  margin-bottom: 20px;
  color: var(--p-txt-c);
  strong{
  display:block;
  }
`;

const Tag = styled.span`
  background-color: var(--t-bg-c);
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 0.9rem;
`;

const MetaInfo = styled.div`
  font-size: 0.8rem;
  color: #999;
  margin-bottom: 20px;
`;

const CommentsSection = styled.div`
  color: var(--p-txt-c);
  margin-top: 30px;
`;

const Comment = styled.div`
  background-color: var(--t-bg-c);
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--p-txt-c);
  border-radius: 5px;
`;

const BlogNotFind = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--p-txt-c);
`;

// New Styled Components for Buttons
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  background-color: ${({ disabled }) => (disabled ? '#999' : '#007bff')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#999' : '#0056b3')};
  }
`;

const CommentsInput = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 1rem;
  background-color: var(--t-bg-c);
  color: var(--p-txt-c);
  border: 1px solid var(--p-txt-c);
  border-radius: 5px;
  resize: none;
`;

const CommentSubmitButton = styled(Button)`
  background-color: ${({ disabled }) => (disabled ? 'var(--p-c)' : '#28a745')};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'var(--t-bg-c)' : '#218838')};
  }
`;

// Mock Data
const posts = [
  {
    id: 'post-123',
    title: 'Comprehensive Guide to JSON for Complex Blogs',
    slug: 'comprehensive-guide-to-json-for-complex-blogs',
    author_name: "Khalid Ait M'hamed",
    author_avatar: ImageProfil,
    author_bio: 'Experienced blogger and JSON enthusiast.',
    social_links: {
      twitter: 'khaimedev',
      facebook: 'khaimedev',
    },
    featured_image: 'https://example.com/featured-image.jpg',
    excerpt: 'A brief overview of the challenges and solutions for modeling blog posts in JSON.',
    content: [
      {
        type: 'paragraph',
        text: 'Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph. For instance, in some styles of writing, particularly journalistic styles, a paragraph can be just one sentence long. Ultimately, a paragraph is a sentence or group of sentences that support one main idea. In this handout, we will refer to this as the “controlling idea,” because it controls what happens in the rest of the paragraph.',
      },
      {
        type: 'image',
        src: 'https://example.com/image1.jpg',
        alt: 'Descriptive image alt text',
      },
    ],
    categories: ['json', 'blogging', 'web development'],
    tags: ['tutorial', 'guide', 'complex-json'],
    published_at: '2024-07-23T11:57:06Z',
    updated_at: '2024-07-23T12:00:00Z',
    status: 'published',
    comments: {
      count: 1,
      items: [
        {
          id: 'comment-789',
          content: 'This is a comment.',
          created_at: '2024-07-22T13:30:00Z',
        },
      ],
    },
    likes: 123,
    shares: 45,
    meta_title: 'Optimized Title for SEO',
    meta_description: 'Meta description for search engines',
    meta_keywords: ['json', 'blog', 'tutorial'],
  },
];

// Helper Component to Render Content Blocks
const renderContentBlock = (block, index) => {
  switch (block.type) {
    case 'paragraph':
      return <Paragraph key={index}>{block.text}</Paragraph>;
    case 'image':
      return <Image key={index} src={block.src} alt={block.alt} />;
    default:
      return null;
  }
};

// Main BlogPost Component
const BlogPost = () => {
  const { id } = useParams();
  const post = posts.find((p) => p.id === id);

  const [likes, setLikes] = useState(post.likes);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState(post.comments.items);
  const [hasLiked, setHasLiked] = useState(false);
  const [hasSubmittedComment, setHasSubmittedComment] = useState(false);

  const handleLike = () => {
    if (!hasLiked) {
      setLikes(likes + 1);
      setHasLiked(true);
    }
  };

  const handleCommentButtonClick = () => setShowCommentInput(!showCommentInput);

  const handleCommentChange = (e) => setNewComment(e.target.value);

  const handleCommentSubmit = () => {
    if (!hasSubmittedComment && newComment.trim()) {
      setComments([...comments, { id: Date.now().toString(), content: newComment, created_at: new Date().toISOString() }]);
      setNewComment('');
      setShowCommentInput(false);
      setHasSubmittedComment(true);
    }
  };

  if (!post) {
    return <BlogNotFind>Post not found</BlogNotFind>;
  }

  return (
    <BlogPostContainer>
      <Title>{post.title}</Title>

      <AuthorInfo>
        <Avatar src={post.author_avatar} alt={post.author_name} />
        <div>
          <AuthorName>{post.author_name}</AuthorName>
          <AuthorBio>{post.author_bio}</AuthorBio>
        </div>
      </AuthorInfo>

      <ContentSection>
        {post.content.map(renderContentBlock)}
      </ContentSection>

      <Categories>
        <strong>Categories:</strong>
        {post.categories.map((category, index) => (
          <Category key={index}>{category}</Category>
        ))}
      </Categories>

      <Tags>
        <strong>Tags:</strong>
        {post.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </Tags>

      <MetaInfo>
        <div>Published at: {new Date(post.published_at).toLocaleDateString()}</div>
        <div>Updated at: {new Date(post.updated_at).toLocaleDateString()}</div>
      </MetaInfo>

      <ButtonContainer>
        <Button onClick={handleLike}>Like ({likes})</Button>
        <Button onClick={handleCommentButtonClick}>Comments ({comments.length})</Button>
        <Button>Share ({post.shares})</Button>
      </ButtonContainer>

      {showCommentInput && (
        <CommentsSection>
          <CommentsInput
            value={newComment}
            onChange={handleCommentChange}
            placeholder="Write your comment here..."
          />
          <CommentSubmitButton onClick={handleCommentSubmit}>Submit</CommentSubmitButton>
        </CommentsSection>
      )}

      <CommentsSection>
        <h3>Comments</h3>
        {comments.map((comment) => (
          <Comment key={comment.id}>
            <p>{comment.content}</p>
            <div>Posted on: {new Date(comment.created_at).toLocaleDateString()}</div>
          </Comment>
        ))}
      </CommentsSection>
    </BlogPostContainer>
  );
};

export default BlogPost;