import React from 'react';
import styled from 'styled-components';

const SocialMediaContainer = styled.div`
    position: absolute;
    left: 0;
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: 640px) {
        position: absolute;
        left: auto;
        bottom: 0;
        margin-left: 0;
        display: flex;
        flex-direction: row;
    }
`;

const SocialMediaLink = styled.a`
    font-size: 28px;
    margin-top: 5px;
    color: var(--p-txt-c);

    @media (max-width: 640px) {
        font-size: 28px;
        margin: 5px;
        color: var(--p-txt-c);
    }
`;

const SocialMediaIcons = () => {
    const socialMedia = {
        facebook: "https://www.facebook.com/khaimedev",
        instagram: "https://www.instagram.com/khaimedev/",
        linkedin: "https://www.linkedin.com/in/khaimed/",
        youtube: "https://www.youtube.com/@khaimedev",
        github: "https://www.github.com/khaimed",
        whatsapp: "https://wa.me/212600611268"
    }
    return (
        <SocialMediaContainer>
            <SocialMediaLink href={socialMedia.facebook} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
            </SocialMediaLink>
            <SocialMediaLink href={socialMedia.instagram} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-instagram" aria-hidden="true"></i>
            </SocialMediaLink>
            <SocialMediaLink href={socialMedia.youtube} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-youtube-square" aria-hidden="true"></i>
            </SocialMediaLink>
            <SocialMediaLink href={socialMedia.linkedin} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
            </SocialMediaLink>
            <SocialMediaLink href={socialMedia.github} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-github" aria-hidden="true"></i>
            </SocialMediaLink>
            <SocialMediaLink href={socialMedia.whatsapp} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
            </SocialMediaLink>
        </SocialMediaContainer>
    );
};

export default SocialMediaIcons;
