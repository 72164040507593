import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDZuumEBw8ZDkzcI4yvSBuUxpvrwwKlzlY",
    authDomain: "portfolioofficial-62957.firebaseapp.com",
    databaseURL: "https://portfolioofficial-62957-default-rtdb.firebaseio.com",
    projectId: "portfolioofficial-62957",
    storageBucket: "portfolioofficial-62957.appspot.com",
    messagingSenderId: "614976553632",
    appId: "1:614976553632:web:1add5990ae6bcc47be7fc5",
    measurementId: "G-0DN45ED92V"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

// Export each individually
export { database, auth };