import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Footer from "../Components/Footer";

// Styled components
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Montserrat', Helvetica, sans-serif;
`;

const LogoError = styled.svg`
  width: 60px;
  margin: 20px auto 0;
`;
const LogoColor = styled.path`
  fill:var(--p-c);
`

const Error404 = styled.section`
  text-align: center;
  font-size: 106px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 70px auto;
`;

const ErrorNumber = styled.span`
  display: inline-block;
  position: relative;
`;

const Four = styled(ErrorNumber)`
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #18C795, #1888C8);
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
  }
  &:before {
    width: 43px;
    height: 156px;
    left: 60px;
    bottom: -43px;
    background:
      linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
      linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
      linear-gradient(to top, #18C795, #18BEC7, #1888C8, #184FC7, #1818C7);
  }
  &:after {
    width: 137px;
    height: 43px;
    transform: rotate(-49.5deg);
    left: -18px;
    bottom: 36px;
    background: linear-gradient(to right, #18C795, #18BEC7, #1888C8, #184FC7, #1818C7);
  }
`;


// Keyframe animation
const bgShadow = keyframes`
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
`;

const Zero = styled(ErrorNumber)`
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
    linear-gradient(to top right, #18C795, #18BEC7, #1888C8, #184FC7, #1818C7, #1515e0, #0d0da9);
  overflow: hidden;
  animation: ${bgShadow} 5s infinite;
  &:before {
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
    width: 90px;
    height: 90px;
    background-color: transparent;
    left: 0px;
    bottom: 0px;
    background:
      linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
      linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
    width: 70px;
    height: 70px;
    left: 43px;
    bottom: 43px;
    background: var(--p-bg-c);
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
`;

const LinkContainer = styled.div`
  text-align: center;
`;

const MoreLink = styled(Link)`
  text-transform: uppercase;
  font-size: 13px;
  background-color: var(--p-c);
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  line-height: 1.5;
  text-decoration: none;
  letter-spacing: 1px;
`;


// NoPage component
const NoPage = () => {
  return (
    <Container>
      <LogoError viewBox="0 0 657.49 723.66" className="logo__error">
        <LogoColor d="M316.15,363.38a84.35,84.35,0,0,1-24.59-52.88l158.94,159L492.93,427,270.25,204.34a145,145,0,0,0,3.48,201.47L393.93,526l42.43-42.42Z" transform="translate(-171.26 -138.17)"/>
        <LogoColor d="M726.27,660.36l-120.2-120.2-42.43,42.42L683.85,702.79a84.35,84.35,0,0,1,24.59,52.88L549.5,596.73l-42.43,42.42L729.75,861.83a145,145,0,0,0-3.48-201.47Z" transform="translate(-171.26 -138.17)"/>
        <LogoColor d="M490,283.09a84.36,84.36,0,0,1,20-54.79V410l60-60V138.17A145,145,0,0,0,430,283.09V350l60,60Z" transform="translate(-171.26 -138.17)"/>
        <LogoColor d="M729.81,264.34a144,144,0,0,0-102.53,42.47l-456,456a145,145,0,0,0,201.46-3.48l456-456A144,144,0,0,0,729.81,264.34ZM330.29,716.93a84.36,84.36,0,0,1-52.88,24.6l392.3-392.29a84.36,84.36,0,0,1,52.88-24.6Z" transform="translate(-171.26 -138.17)"/>
      </LogoError>
      <Error404>
        <Four></Four>
        <Zero></Zero>
        <Four></Four>
      </Error404>
      <LinkContainer>
        <MoreLink to="/">Come Back</MoreLink>
      </LinkContainer>
      <Footer/>
    </Container>
  );
};

export default NoPage;
