// src/DynamicAlertsTable.js
import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  h1, p{
    color: var(--p-txt-c);
  }
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const columns = [
  {
    name: 'Alert ID',
    selector: row => row.id,
    sortable: true,
  },
  {
    name: 'Timestamp',
    selector: row => row.timestamp,
    sortable: true,
  },
  {
    name: 'Message',
    selector: row => row.message,
    sortable: true,
  },
  {
    name: 'Severity',
    selector: row => row.severity,
    sortable: true,
  },
  {
    name: 'Category',
    selector: row => row.category,
    sortable: true,
  },
];

const initialAlerts = [
  'System reboot required',
  'Unauthorized access detected',
  'Failed login attempt',
  'Software update available',
  'Network latency issue',
];

const severities = ['Low', 'Medium', 'High', 'Critical'];
const categories = ['System', 'Security', 'Network', 'Application'];

const DynamicAlertsTable = () => {
  const [alerts, setAlerts] = useState([]);
  const [alertCount, setAlertCount] = useState(1);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const addAlert = useCallback(() => {
    const newAlert = {
      id: alertCount,
      timestamp: new Date().toLocaleString(),
      message: initialAlerts[Math.floor(Math.random() * initialAlerts.length)],
      severity: severities[Math.floor(Math.random() * severities.length)],
      category: categories[Math.floor(Math.random() * categories.length)],
    };

    setAlerts(prevAlerts => [...prevAlerts, newAlert]);
    setAlertCount(alertCount + 1);
  }, [alertCount]);

  const removeAlert = useCallback(() => {
    setAlerts(prevAlerts => prevAlerts.slice(1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      addAlert();
      if (alerts.length > 10) {
        removeAlert();
      }
    }, Math.random() * 10000);

    return () => clearInterval(interval);
  }, [alerts, addAlert, removeAlert]);

  const handleRowClicked = row => {
    setSelectedRowId(row.id);
  };

  useEffect(() => {
    const handleCopy = event => {
      if (selectedRowId && event.ctrlKey && event.key === 'c') {
        const selectedRow = alerts.find(alert => alert.id === selectedRowId);
        if (selectedRow) {
          const rowText = `${selectedRow.id}\t${selectedRow.timestamp}\t${selectedRow.message}\t${selectedRow.severity}\t${selectedRow.category}`;
          navigator.clipboard.writeText(rowText);
          alert('Row data copied!');
        }
      }
    };

    window.addEventListener('keydown', handleCopy);
    return () => window.removeEventListener('keydown', handleCopy);
  }, [selectedRowId, alerts]);

  return (
    <Container>
      <h1>Dynamic Fake Alerts Table</h1>
      <p>Please click in the row and press ctrl+c</p>
      <TableWrapper>
        <DataTable
          columns={columns}
          data={alerts}
          onRowClicked={handleRowClicked}
          highlightOnHover
          pointerOnHover
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer',
                backgroundColor:'var(--t-bg-c)',
                color:'var(--p-txt-c)',
                '&:hover, &:active, &:focus, &:focus-within, &:focus-visible, &:visited, &:target': {
                backgroundColor: 'var(--s-bg-c)',
                },
              },
            },
            headCells: {
              style: {
                backgroundColor:'var(--t-bg-c)',
                color:'var(--p-txt-c)',
                fontWeight: 'bold',
              },
            }
          }}
          className={(row) => row.id === selectedRowId ? 'selected-row' : ''}
        />
      </TableWrapper>
    </Container>
  );
};

export default DynamicAlertsTable;
