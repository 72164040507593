import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'


const CvcContainer = styled.div`
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

    .btn {
        width: max-content;
        display: inline-block;
        color: var(--p-c);
        padding: 0.75rem 1.2rem;
        border-radius: 0.4rem;
        cursor: pointer;
        border: 1px solid var(--p-c);
        transition: var(--transition);

        &.btn-primary {
            background: var(--p-c);
            color: var(--s-bg-c);
        }

        &:hover {
            background: var(--p-txt-c);
            color: var(--p-bg-c);
            border-color: transparent;
        }
    }
    @media (max-width: 375px){
        flex-direction: column;
        align-items: center;
    }
`;
const ButtonDownload = styled(Link)`
    width: max-content;
    display: inline-block;
    background: var(--p-c);
    color: var(--p-bg-c);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--s-bg-c);
    &:hover{
    background: var(--p-txt-c);
    color: var(--p-bg-c);
    border-color: transparent;
    }
`
const ButtonLink = styled(Link)`
    width: max-content;
    display: inline-block;
    color: var(--p-c);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--p-c);
    &:hover{
    background: var(--p-txt-c);
    color: var(--p-bg-c);
    border-color: transparent;
    }
`

const Cvc = () => {
    const {t} = useTranslation('global');
    return (
        <CvcContainer>
            <ButtonDownload to="/resume">{t("home.button.download")}</ButtonDownload>
            <ButtonLink to="contact" >{t("home.button.contact")}</ButtonLink>
        </CvcContainer>
    );
};

export default Cvc;
