import React, { createContext, useEffect } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const setDarkMode = () => {
    const body = document.querySelector('body');
    body.setAttribute('data-theme', 'dark');
    localStorage.setItem('selectedTheme', 'dark');
  };

  const setLightMode = () => {
    const body = document.querySelector('body');
    body.setAttribute('data-theme', 'light');
    localStorage.setItem('selectedTheme', 'light');
  };

  useEffect(() => {
    const selectedTheme = localStorage.getItem('selectedTheme');

    if (selectedTheme === 'dark') {
      setDarkMode();
    } else {
      setLightMode();
    }
  }, []);

  const toggleDarkMode = () => {
    const body = document.querySelector('body');
    const isDarkMode = body.getAttribute('data-theme') === 'dark';
    if (isDarkMode) {
      setLightMode();
    } else {
      setDarkMode();
    }
  };

  return (
    <DarkModeContext.Provider value={{ toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export default DarkModeContext;
