import React from 'react';
import About from '../Pages/About';
import Blog from '../Pages/Blog';
import Portfolio from '../Pages/Portfolio';
import Contact from '../Pages/Contact';
import GoHome from '../Components/GoHome';
import GoBack from '../Components/GoBack';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TabTitle } from '../Config/TitleName'

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
`
const HeaderContentLeft = styled.div`
    display: flex;
    align-items: center;
`

const HeaderContentRight = styled.div`
    display:block;
`

const TitlePage = styled.h2`
    color:var(--p-c);
`

const Content = ({ page }) => {
    const { t } = useTranslation("global");
    let titlePage = t(`${page}.page`);
    if (!titlePage) {
        titlePage = page.charAt(0).toUpperCase() + page.slice(1);
    }
    TabTitle(titlePage)
    
    return (
        <>
            <HeaderContent>
                <HeaderContentLeft>
                    <GoBack />
                    <TitlePage>{titlePage}</TitlePage>
                </HeaderContentLeft>
                <HeaderContentRight>
                    <GoHome />
                </HeaderContentRight>
            </HeaderContent>
            {page === 'about' && <About />}
            {page === 'blog' && <Blog/>}
            {page === 'portfolio' && <Portfolio />}
            {page === 'contact' && <Contact />}
        </>
    );
};

export default Content;