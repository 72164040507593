import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ContainerAnimation = styled.div`
    background: linear-gradient(to bottom, var(--p-bg-c), var(--s-bg-c));
    width: 100%;
    height: 100vh;
`;

const Circles = styled.ul`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const animate = keyframes`
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        background-color: #a9b5bd;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        background-color: #627580;
    }
`;

const Circle = styled.li`
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 80px;
    background: var(--background-circles);
    animation: ${animate} 25s linear infinite;
    bottom: -300px;
    border-start-start-radius: 200px;
    border-end-end-radius: 200px;

    &:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 320px;
        animation-delay: 0s;
    }

    &:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 80px;
        animation-delay: 2s;
        animation-duration: 12s;
    }

    &:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 80px;
        animation-delay: 4s;
    }

    &:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 240px;
        animation-delay: 0s;
        animation-duration: 18s;
    }

    &:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 80px;
        animation-delay: 0s;
    }

    &:nth-child(6) {
        left: 75%;
        width: 50px;
        height: 200px;
        animation-delay: 3s;
    }

    &:nth-child(7) {
        left: 35%;
        width: 15px;
        height: 60px;
        animation-delay: 7s;
    }

    &:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 100px;
        animation-delay: 15s;
        animation-duration: 45s;
    }

    &:nth-child(9) {
        left: 20%;
        width: 5px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 35s;
    }

    &:nth-child(10) {
        left: 85%;
        width: 50px;
        height: 200px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
`;

const Field = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    padding-bottom: 50px;
`;

const Scroll = styled.div`
    width: 60px;
    height: 60px;
    border: 2px solid var(--p-c);
    border-radius: 50%;
    position: relative;
    animation: down 1.5s infinite;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 18px;
        width: 18px;
        height: 18px;
        border-left: 2px solid var(--p-c);
        border-bottom: 2px solid var(--p-c);
        transform: rotate(-45deg);
    }

    @keyframes down {
        0% {
            transform: translate(0);
        }
        20% {
            transform: translateY(15px);
        }
        40% {
            transform: translate(0);
        }
    }
`;


const BackgroundAnimate = () => {
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            gsap.to(scrollRef.current, {
                opacity: 0,
                scrollTrigger: {
                    scrub: true,
                    trigger: '.jaBtiH',
                    start: '0 100%',
                    endTrigger: '.jaBtiH',
                    end: '0 50%'
                }
            });
        }
    }, []);

    function bottom() {
        document.querySelector('#footer').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <ContainerAnimation>
            <Circles>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
                <Circle></Circle>
            </Circles>
            <Field>
                <Scroll ref={scrollRef} onClick={bottom}></Scroll>
            </Field>
        </ContainerAnimation>
    );
}

export default BackgroundAnimate;
