import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Config/firebase';

const LoginLabel = styled.div`
  display: flex;
  justify-content: space-between;
  i {
    display: flex;
    align-items: center;
  }
  input {
    margin: 4px 8px;
    padding: 3px 6px;
    border: 1px solid var(--s-bg-c);
    border-radius: 4px;
  }
`;

const Button = styled.button`
  margin: 4px 8px;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: var(--p-c);
  color: var(--p-txt-c);
  cursor: pointer;
  text-align: center;
`;

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    backgroundColor: 'var(--p-bg-c-transparent)',
    zIndex: '999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'var(--p-bg-c)',
    color: 'var(--p-txt-c)',
    padding: '20px',
    borderRadius: '10px',
    border: '1px solid #ccc',
  },
};

const LoginModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onRequestClose(); // Close the modal upon successful login
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Login Modal"
      style={customStyles}
    >
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <LoginLabel>
          <i className="fa fa-solid fa-user" />
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </LoginLabel>
        <LoginLabel>
          <i className="fa fa-solid fa-lock" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </LoginLabel>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Button type="submit">Login</Button>
      </form>
      <Button onClick={onRequestClose}>Close</Button>
    </Modal>
  );
};

export default LoginModal;
