import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import styled from 'styled-components';
import profileImage from '../assets/profile.png'
import { useTranslation } from 'react-i18next';
import ChangeLanguageButton from './ChangeLanguageButton';
import GoHome from './GoHome';

// Styled Components
const Content = styled.div`
    height: 100vh;
`
const Main = styled.div`
    width: 100%;
    height: calc(-60px + 100vh);
    padding: 30px 0;
    overflow-y: scroll;
    display: inline-flex;
`
const PdfContent = styled.div`
    margin:auto;
`
const Resume = styled.div`
    display: flex;
    width: 8.27in;
    height: 11.69in;
`
const HeaderResume = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const OptionSide = styled.div`
    padding: 10px;
`
const LeftContainer = styled.div`
    width: 3.27in;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
`
const RightContainer = styled.div`
    background-color: #f7f7f7;
    color: #093047;
    padding: 30px 20px;
`
const ProfileImage = styled.img`
    width: 200px;
    height: 200px;
    margin: 20px 0 10px;
    border-radius: 50%;
`
const Container = styled.div`
    font-size: 15px;
    padding: 0 20px;
`
const Between = styled.div`
    margin: 10px 0;
`
const Spot = styled.div`
    display: grid;
    color: #def3ff;
    grid-auto-flow: column;
    column-gap: 5px;
    grid-template-columns: max-content;
    margin-bottom: 5px;
`
const LeftLink = styled.a`
    color: #def3ff;
    text-decoration-line: none;
`
const RightLink = styled.a`
    font-size: 13px;
    color: #2F6F94;
    text-decoration-line: none;
`
const Svg = styled.svg`
    width: 16px;
    height: 16px;
    fill: #def3ff;
`
const Part = styled.div`
    margin: 0 20px;
    color: #def3ff;
    display: flex;
    flex-direction: column;
`
const LeftTitle = styled.h3`
    color: #def3ff;
`
const RightTitle = styled.h1`
    font-size: 40px;
    color:#093047;
    margin: 0;
`
const MiniTitle =styled.h2`
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    color: #1888c8;
    font-family: 'Poppins', sans-serif;
`
const Box = styled.div`
    padding: 10px;
    background-color: #dddddd;
    border-radius: 10px;
    margin-bottom: 20px;
`
const DisFlex = styled.div`
    display: flex;
    justify-content: space-between;
`
const Button = styled.button`
    position: inherit;
    font-size: 0.9rem;
    height: 24px;
    padding: 3px 10px;
    margin: 10px;
    background: var(--p-c);
    cursor: pointer;
    border: none;
    color: #ccc;
    transform: skewX(-7deg);
    &:hover{
        background: var(--s-c);
    }
`
// SVG ICONS
const PhoneSvg = () => (
    <Svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path  stroke="#def3ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"/>
    </Svg>
)
const EmailSvg = () => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
        <path d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8V385.92z"/>
    </Svg>
)
const WebsiteSvg = () => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490">
        <path d="M245,0C109.69,0,0,109.69,0,245s109.69,245,245,245s245-109.69,245-245S380.31,0,245,0z M31.401,260.313h52.542
            c1.169,25.423,5.011,48.683,10.978,69.572H48.232C38.883,308.299,33.148,284.858,31.401,260.313z M320.58,229.688
            c-1.152-24.613-4.07-47.927-8.02-69.572h50.192c6.681,20.544,11.267,43.71,12.65,69.572H320.58z M206.38,329.885
            c-4.322-23.863-6.443-47.156-6.836-69.572h90.913c-0.392,22.416-2.514,45.709-6.837,69.572H206.38z M276.948,360.51
            c-7.18,27.563-17.573,55.66-31.951,83.818c-14.376-28.158-24.767-56.255-31.946-83.818H276.948z M199.961,229.688
            c1.213-24.754,4.343-48.08,8.499-69.572h73.08c4.157,21.492,7.286,44.818,8.5,69.572H199.961z M215.342,129.492
            c9.57-37.359,21.394-66.835,29.656-84.983c8.263,18.148,20.088,47.624,29.66,84.983H215.342z M306.07,129.492
            c-9.77-40.487-22.315-73.01-31.627-94.03c11.573,8.235,50.022,38.673,76.25,94.03H306.07z M215.553,35.46
            c-9.312,21.02-21.855,53.544-31.624,94.032h-44.628C165.532,74.13,203.984,43.692,215.553,35.46z M177.44,160.117
            c-3.95,21.645-6.867,44.959-8.019,69.572h-54.828c1.383-25.861,5.968-49.028,12.65-69.572H177.44z M83.976,229.688H31.401
            c1.747-24.545,7.481-47.984,16.83-69.572h46.902C89.122,181.002,85.204,204.246,83.976,229.688z M114.577,260.313h54.424
            c0.348,22.454,2.237,45.716,6.241,69.572h-47.983C120.521,309.288,115.92,286.115,114.577,260.313z M181.584,360.51
            c7.512,31.183,18.67,63.054,34.744,95.053c-10.847-7.766-50.278-38.782-77.013-95.053H181.584z M273.635,455.632
            c16.094-32.022,27.262-63.916,34.781-95.122h42.575C324.336,417.068,284.736,447.827,273.635,455.632z M314.759,329.885
            c4.005-23.856,5.894-47.118,6.241-69.572h54.434c-1.317,25.849-5.844,49.016-12.483,69.572H314.759z M406.051,260.313h52.548
            c-1.748,24.545-7.482,47.985-16.831,69.572h-46.694C401.041,308.996,404.882,285.736,406.051,260.313z M406.019,229.688
            c-1.228-25.443-5.146-48.686-11.157-69.572h46.908c9.35,21.587,15.083,45.026,16.83,69.572H406.019z M425.309,129.492h-41.242
            c-13.689-32.974-31.535-59.058-48.329-78.436C372.475,68.316,403.518,95.596,425.309,129.492z M154.252,51.06
            c-16.792,19.378-34.636,45.461-48.324,78.432H64.691C86.48,95.598,117.52,68.321,154.252,51.06z M64.692,360.51h40.987
            c13.482,32.637,31.076,58.634,47.752,78.034C117.059,421.262,86.318,394.148,64.692,360.51z M336.576,438.54
            c16.672-19.398,34.263-45.395,47.742-78.03h40.99C403.684,394.146,372.945,421.258,336.576,438.54z"/>
    </Svg>
)
const LocalSvg = () =>(
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297">
        <path d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645
            c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645
            C259.253,49.703,209.57,0,148.5,0z M148.5,272.689c-22.049-21.366-90.243-93.029-90.243-161.892
            c0-49.784,40.483-90.287,90.243-90.287s90.243,40.503,90.243,90.287C238.743,179.659,170.549,251.322,148.5,272.689z"/>
        <path d="M148.5,59.183c-28.273,0-51.274,23.154-51.274,51.614c0,28.461,23.001,51.614,51.274,51.614
            c28.273,0,51.274-23.153,51.274-51.614C199.774,82.337,176.773,59.183,148.5,59.183z M148.5,141.901
            c-16.964,0-30.765-13.953-30.765-31.104c0-17.15,13.801-31.104,30.765-31.104c16.964,0,30.765,13.953,30.765,31.104
            C179.265,127.948,165.464,141.901,148.5,141.901z"/>
    </Svg>
)
const FacebookSvg = () =>(
    <Svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26">
        <path d="M13,0C5.82,0,0,5.82,0,13c0,6.518,4.801,11.899,11.057,12.839v-9.394H7.84v-3.417h3.217v-2.274 c0-3.765,1.834-5.417,4.963-5.417c1.498,0,2.291,0.111,2.666,0.162v2.983h-2.134c-1.328,0-1.792,1.259-1.792,2.679v1.868h3.893 l-0.528,3.417H14.76v9.422C21.105,25.006,26,19.581,26,13C26,5.82,20.18,0,13,0z"/>
    </Svg>
)
const InstagramSvg = () =>(
    <Svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
        <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
    </Svg>
)
const LinkedinSvg = () => (
    <Svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/>
    </Svg>
)
const GithubSvg = () => (
    <Svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50">
        <path d="M17.791,46.836C18.502,46.53,19,45.823,19,45v-5.4c0-0.197,0.016-0.402,0.041-0.61C19.027,38.994,19.014,38.997,19,39 c0,0-3,0-3.6,0c-1.5,0-2.8-0.6-3.4-1.8c-0.7-1.3-1-3.5-2.8-4.7C8.9,32.3,9.1,32,9.7,32c0.6,0.1,1.9,0.9,2.7,2c0.9,1.1,1.8,2,3.4,2 c2.487,0,3.82-0.125,4.622-0.555C21.356,34.056,22.649,33,24,33v-0.025c-5.668-0.182-9.289-2.066-10.975-4.975 c-3.665,0.042-6.856,0.405-8.677,0.707c-0.058-0.327-0.108-0.656-0.151-0.987c1.797-0.296,4.843-0.647,8.345-0.714 c-0.112-0.276-0.209-0.559-0.291-0.849c-3.511-0.178-6.541-0.039-8.187,0.097c-0.02-0.332-0.047-0.663-0.051-0.999 c1.649-0.135,4.597-0.27,8.018-0.111c-0.079-0.5-0.13-1.011-0.13-1.543c0-1.7,0.6-3.5,1.7-5c-0.5-1.7-1.2-5.3,0.2-6.6 c2.7,0,4.6,1.3,5.5,2.1C21,13.4,22.9,13,25,13s4,0.4,5.6,1.1c0.9-0.8,2.8-2.1,5.5-2.1c1.5,1.4,0.7,5,0.2,6.6c1.1,1.5,1.7,3.2,1.6,5 c0,0.484-0.045,0.951-0.11,1.409c3.499-0.172,6.527-0.034,8.204,0.102c-0.002,0.337-0.033,0.666-0.051,0.999 c-1.671-0.138-4.775-0.28-8.359-0.089c-0.089,0.336-0.197,0.663-0.325,0.98c3.546,0.046,6.665,0.389,8.548,0.689 c-0.043,0.332-0.093,0.661-0.151,0.987c-1.912-0.306-5.171-0.664-8.879-0.682C35.112,30.873,31.557,32.75,26,32.969V33 c2.6,0,5,3.9,5,6.6V45c0,0.823,0.498,1.53,1.209,1.836C41.37,43.804,48,35.164,48,25C48,12.318,37.683,2,25,2S2,12.318,2,25 C2,35.164,8.63,43.804,17.791,46.836z"/>
    </Svg>
)

// Resume
// left Container
const InfoContact = () => (
    <Between>
        <Spot>
            <PhoneSvg/>
            <LeftLink  href="tel:212660611268">06606-11268</LeftLink>
        </Spot>
        <Spot>
            <EmailSvg/>
            <LeftLink  href="mailto:khalid.aitmhamed@hotmail.com">Khalid.aitmhamed@hotmail.com</LeftLink>
        </Spot>
        <Spot>
            <WebsiteSvg/>
            <LeftLink  href="https://www.khaimed.com">Khaimed.com</LeftLink>
        </Spot>
        <Spot>
            <LocalSvg/>
            <LeftLink href="https://maps.app.goo.gl/6j9LNR9rKRy4Sdjd9">Casablanca, Morocco</LeftLink>
        </Spot>
    </Between>
)
const InfoSocialMedia = ({t}) =>(
    <Between>
        <LeftTitle>{t('home.resume.socialmedia.name')}</LeftTitle>
        <div>
            <Spot>
                <FacebookSvg/>
                <LeftLink  href="https://www.facebook.com/khaimedev">
                    /Khaimedev
                </LeftLink>
            </Spot>
            <Spot>
                <InstagramSvg/>
                <LeftLink  href="https://www.instagram.com/khaimedev/">
                    /Khaimedev
                </LeftLink>
            </Spot>
            <Spot>
                <LinkedinSvg/>
                <LeftLink  href="https://www.linkedin.com/in/khaimed/">
                    /in/Khaimed
                </LeftLink>
            </Spot>
            <Spot>
                <GithubSvg/>
                <LeftLink  href="https://github.com/khaimed">
                    /Khaimed
                </LeftLink>
            </Spot>
        </div>
    </Between>
)
const InfoLanguage = ({t}) => (
    <Between>
      <LeftTitle>{t('home.resume.languages.name')}</LeftTitle>
      <div>
        {Object.keys(t('home.resume.languages.language', { returnObjects: true })).map((key) => {
          const language = t(`home.resume.languages.language.${key}`, { returnObjects: true });
          return (
            <Part key={key}>
              {language.name}: {language.level}
            </Part>
          );
        })}
      </div>
    </Between>
)
const InfoInterest = ({t}) => (
    <Between>
      <LeftTitle>{t('home.resume.interests.name')}</LeftTitle>
      <div>
        {Array.isArray(t('home.resume.interests.interest', { returnObjects: true }))
          ? t('home.resume.interests.interest', { returnObjects: true }).map((interest, index) => (
              <Part key={index}>{interest}</Part>
            ))
          : <Part>No interests available.</Part>}
      </div>
    </Between>
)
const InfoSkills = ({ t }) => (
    <Between>
        <LeftTitle>{t('home.resume.skills.name')}</LeftTitle>
        <div>
        {t('home.resume.skills.context', { returnObjects: true }).map((skill, index) => (
            <Part key={index}>
            <b>{skill.name}</b>
            <Part>
                {skill.elements.slice(0, -1).join(', ') + (skill.elements.length > 1 ? ', ' : '') + skill.elements[skill.elements.length - 1] + '.'}
            </Part>
            </Part>
        ))}
        </div>
    </Between>
)

const LeftContent = ({t}) => (
    <LeftContainer>
        <ProfileImage src={profileImage} alt="profile"/>
        <Container>
            <InfoContact/>
            <InfoSocialMedia t={t}/>
            <InfoLanguage t={t}/>
            <InfoInterest t={t}/>
            <InfoSkills t={t}/>
        </Container>
    </LeftContainer>
)
// right Container
const AboutMe = ({t}) => (
    <>
        <div>
            <RightTitle>{t("home.fullname")}</RightTitle>
            <h3>{t("about.aboutCards.1.text")}</h3>
        </div>
        <div>
            <p>{t("about.paragraph")}</p>
        </div>
    </>
)
const Educations = ({t}) => (
    <>
        <MiniTitle>{t('about.education.title')}</MiniTitle>
        <Box>
            {t('about.education.ecole', { returnObjects: true }).map((school, index) => (
                <div key={index}>
                    <RightLink href={school.link} target="_blank" rel="noopener noreferrer">
                        <h4>{school.name}</h4>
                    </RightLink>
                    <h5>{school.level}</h5>
                    <DisFlex>
                        <span>{school.date}</span>
                        <span>{school.local}</span>
                    </DisFlex>
                </div>
            ))}
        </Box>
    </>
)
const Experience = ({t}) => (
    <>
        <MiniTitle>{t('about.experience.title')}</MiniTitle>
        <Box>
            {t('about.experience.job', { returnObjects: true }).map((job, index) => (
                <div key={index}>
                    <RightLink href={job.link} target="_blank" rel="noopener noreferrer">
                        <h4>{job.name}</h4>
                    </RightLink>
                    <h5>{job.role}</h5>
                    <DisFlex>
                        <span>{job.date}</span>
                        <span>{job.local}</span>
                    </DisFlex>                 
                </div>
            ))}
        </Box>
    </>
)
const Certificates = ({t}) => (
    <>
        <MiniTitle>{t('about.certificate.title')}</MiniTitle>
        <Box>
        {t('about.certificate.earned', { returnObjects: true }).map((earned, index) => (
            <RightLink key={index} href={earned.link} target="_blank" rel="noopener noreferrer">
                <h4>{earned.name}</h4>
            </RightLink>
          ))}
        </Box>
    </>
)
const Projects = ({t}) => (
    <>
        <MiniTitle>{t('about.projects.title')}</MiniTitle>
        <Box>
        {t('about.projects.project', { returnObjects: true }).map((project, index) => (
            <DisFlex key={index}><b>{project.name}</b><span>{project.date}</span></DisFlex>
          ))}
        </Box>
    </>
)
const RightContent = ({t}) => (
    <RightContainer>
        <AboutMe t={t}/>
        <Experience t={t}/>
        <Educations t={t}/>
        <Certificates t={t}/>
        <Projects t={t}/>
    </RightContainer>
)
const ResumeContent = ({t}) => (
    <Resume>
        <LeftContent t={t}/>
        <RightContent t={t}/>
    </Resume>
)

const generatePdf = () => {
    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
    });

    const pdfContent = document.querySelector('#pdf-content');

    html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgProps = doc.getImageProperties(imgData);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        doc.save('resume.pdf');
    });
};

// Result
const PdfGenerator = () => {
    const {t} = useTranslation('global');

    return (
        <Content>
            <HeaderResume>
                <OptionSide>
                    <ChangeLanguageButton/>
                </OptionSide>
                <Button onClick={generatePdf}>{t("home.button.download")}</Button>
                <div>
                    <GoHome/>
                </div>
            </HeaderResume>
            <Main>
                <PdfContent id="pdf-content">
                    <ResumeContent t={t}/>
                </PdfContent>
            </Main>
        </Content>
    );
};

export default PdfGenerator;