import React from 'react';
import styled, { css } from 'styled-components';

const MenuButton = styled.button`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  margin: 10px;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  span {
    position: relative;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: #1888c8;
    margin: auto;
    transition: all 0.2s ease-in-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    left: 5px;
    border-radius: 2px;
    background-color: #1888c8;
    transition: all 0.4s ease-in-out;
  }

  &:before {
    top: 10px;
  }

  &:after {
    bottom: 10px;
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      span {
        background-color: transparent;
      }
      &:before {
        top: 18px;
        transform: rotate(-45deg);
      }
      &:after {
        bottom: 18px;
        transform: rotate(45deg);
      }
    `}
`;

const GoMenu = ({ isMenuOpen, onClick }) => {
  return (
    <MenuButton isMenuOpen={isMenuOpen} onClick={onClick}>
      <span />
    </MenuButton>
  );
};

export default GoMenu;
