import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabTitle } from '../Config/TitleName';

const PostNewsSider = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  margin-bottom: 1.5rem;
  justify-items: center;
}
`
const PostContainer = styled.div`
    max-width: 300px;
    margin: 20px;
    text-align: center;
`;

const PostThumb = styled.div`
    overflow: hidden;
    border-radius: 5px 5px 0 0;
`;

const PostContent = styled.div`
  background-color: var(--t-bg-c);
  border-radius: 0 0 5px 5px;
  padding: 20px 25px 25px;
`;

const PostImage = styled.img`
  width: 100%;
  height: 200px;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.2);
`;

const PostTitle = styled.h3`
    margin-top: 10px;
    font-size: 18px;
    color: var(--p-txt-c);
`;

const PostLink = styled(Link)`
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover ${PostImage}{
    transform: scale(1.5);
  }
  &:hover  ${PostTitle} {
    color: var(--p-c);
  }
`;

const Post = ({ href, imgSrc, alt, title }) => (
    <PostContainer>
      <PostLink to={href}>
        <PostThumb>
                <PostImage decoding="async" src={imgSrc} alt={alt} />
        </PostThumb>
        <PostContent>
            <PostTitle>{title}</PostTitle>
        </PostContent>
      </PostLink>
    </PostContainer>
);

const PortfolioList = () => {
  const {t} = useTranslation("global")
  TabTitle(t("portfolio.page"))
  return(
    <PostNewsSider>
      {t('portfolio.projects.items', { returnObjects: true }).map((project, idx) => (
        <Post key={idx}
          href={project.link}
          imgSrc={project.image}
          alt={project.alt}
          title={project.title}
        />
      ))}
    </PostNewsSider>
  )
}

export default PortfolioList;
