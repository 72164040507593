import React from 'react'
import { useTranslation } from "react-i18next";
import styled from 'styled-components'
import myImage from '../assets/about.jpg'
import Footer from '../Components/Footer';

// About
const AboutPage = styled.div`
  width: 100%;
  height:calc(100dvh - 60px);
  overflow-y: auto;
`
const Section = styled.section`
  position: relative;
  padding: 22px 20px;
  margin:auto;
  @media (min-width: 992px) {max-width: 960px;}
  @media (min-width: 1200px) {max-width: 1140px;}
`;

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 50%;
  justify-content: space-evenly;
  @media (max-width: 768px){
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const AboutMe = styled.div`
    display: grid;
    place-items: center;
    padding: 2rem 0;
    align-items: start;
`;

const AboutMeImage = styled.img`
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-10deg);
  transition: all 400ms ease;
  display: block;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 65%;
  }
  &:hover {
    transform: rotate(0);
  }
`;

const AboutName = styled.p`
  font-size: 3rem;
  color: var(--p-c);
   @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AboutCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const AboutCard = styled.article`
  background: var(--t-bg-c);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: .6rem;
  text-align: center;
  transition: all 400ms ease;

  &:hover {
    background: transparent;
    border-color: var(--p-txt-c);
    cursor: default;
  }
`;

const CardIcon = styled.i`
  color: var(--p-c);
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h5`
  font-size: 0.95rem;
  color: var(--p-txt-c);
`;

const CardText = styled.small`
  font-size: .7rem;
  color: var(--p-txt-c);
`;

const AboutParagraph = styled.p`
  margin: 2rem 0 2.6rem;
  color: var(--p-txt-c);
`;

// Education Experience Certificates
const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  color: var(--p-c);
  font-family: "Poppins", sans-serif;
  `
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
const ColLG12 = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Box = styled.div`
  background: var(--t-bg-c);
  color: var(--p-txt-c);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 40px 40px;
  transition: all 400ms ease;
  &:hover {
    background: transparent;
    border-color: var(--p-txt-c);
    cursor: default;
  }
`
const BoxImg = styled.img`
  float:inline-start;
  width:90px;
  margin:0 10px 0;
  border-radius: 50%;
  @media  only screen and (max-width: 425px) {float:none;}
`
const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media only screen and (max-width: 670px){flex-direction: column;}
`
const BoxTools = styled.div`
text-align: right;
`
const BoxLink = styled.a`
  color:var(--p-c);
`
const BoxList = styled.ul`
  display: table-cell;
`
const BoxInsideList = styled.li`
  list-style: inside;
`

// Skills
const SectionGap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 1.5rem;
  @media  only screen and (max-width: 300px) {grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));}
`
const SectionGapTitle = styled.h4`
  color:var(--p-c);
  text-decoration: none;
  margin-bottom: 15px;
`
const BoxIcons = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
`
const Figure = styled.figure`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin :0 .5rem 1rem;
`
const FigureImg = styled.img`
  width:40px;
  height:40px;
`

const AboutIntro = ({ t }) => (
  <Section>
        <AboutContainer>
          <AboutMe>
              <AboutMeImage src={myImage} alt="Me" />
          </AboutMe>

          <AboutContent>
            <AboutName>{t("home.fullname")}</AboutName>
            <AboutParagraph>{t("about.paragraph")}</AboutParagraph>
            <AboutCards>
              {t('about.aboutCards', { returnObjects: true }).map((card, index) => (
                <AboutCard key={index}>
                  <CardIcon className={`fa fa-solid ${card.icon}`} />
                  <CardTitle>{card.title}</CardTitle>
                  <CardText>{card.text}</CardText>
                </AboutCard>
            ))}
            </AboutCards>
          </AboutContent>

        </AboutContainer>

      </Section>
);

const EducationSection = ({ t }) => (
    <Section>
      <SectionTitle>{t('about.education.title')}</SectionTitle>
      <Row>
        {t('about.education.ecole', { returnObjects: true }).map((school, index) => (
          <ColLG12 key={index}>
            <Box>
              <BoxLink href={school.link} target="_blank" rel="noopener noreferrer">
                <BoxImg src={school.image} alt={school.name} />
              </BoxLink>
              <BoxLink href={school.link} target="_blank" rel="noopener noreferrer">
                <h4>{school.name}</h4>
              </BoxLink>
              <h5><em>{school.level}</em></h5>
              <BoxInfo>
                <span><em>{school.date}</em></span>
                <span><em>{school.local}</em></span>
              </BoxInfo>
              <BoxList>
                {school.bulletpoint.map((point, idx) => (
                  <BoxInsideList key={idx}>{point}</BoxInsideList>
                ))}
              </BoxList>
            </Box>
          </ColLG12>
        ))}
      </Row>
    </Section>
  );

const ExperienceSection = ({ t }) => (
  <Section>
    <SectionTitle>{t('about.experience.title')}</SectionTitle>
    <Row>
      {t('about.experience.job', { returnObjects: true }).map((job, index) => (
        <ColLG12 key={index}>
          <Box>
            <BoxLink href={job.link} target="_blank" rel="noopener noreferrer">
              <BoxImg src={job.image} alt={job.name} />
            </BoxLink>
            <h4><BoxLink href={job.link} target="_blank" rel="noopener noreferrer">{job.name}</BoxLink></h4>
            <h5><em>{job.role}</em></h5>
            <BoxInfo>
              <span><em>{job.date}</em></span>
              <span><em>{job.local}</em></span>
            </BoxInfo>
            <BoxList>
              {job.bulletpoint.map((point, idx) => (
                <BoxInsideList key={idx}>{point}</BoxInsideList>
              ))}
            </BoxList>
          </Box>
        </ColLG12>
      ))}
    </Row>
  </Section>
);
const CertificatesSection = ( {t} ) => (
  <Section>
    <SectionTitle>{t('about.certificate.title')}</SectionTitle>
    <Row>
      {t('about.certificate.earned', { returnObjects: true }).map((earned, index) => (
        <ColLG12>
          <Box>
              <h4><BoxLink key={index} href={earned.link} target="_blank" rel="noopener noreferrer">{earned.name}</BoxLink></h4>
          </Box>
        </ColLG12>
      ))}
    </Row>
  </Section>
)
const ProjectsSection = ( {t} ) => (
  <Section>
    <SectionTitle>{t('about.projects.title')}</SectionTitle>
    <Row>
          {t('about.projects.project', { returnObjects: true }).map((project, index) => (
            <ColLG12 key={index}>
              <Box>
                <BoxInfo>
                  <h4><BoxLink  href={project.link} target="_blank" rel="noopener noreferrer">{project.name}</BoxLink></h4>
                  <span><em>{project.date}</em></span>
                </BoxInfo>
                <BoxTools>{project.tools.slice(0, -1).join(', ') + (project.tools.length > 1 ? ', ' : '') + project.tools[project.tools.length - 1] + '.'}</BoxTools>
              </Box>
            </ColLG12>
          ))}
    </Row>
  </Section>
)
const SkillsSection = ( {t} ) => (
    <Section>
      <SectionTitle>{t('about.skills.title')}</SectionTitle>
      <ColLG12>
        <SectionGap>
        {t('about.skills.context', { returnObjects: true }).map((context, index) => (
          <Box key={index}>
            <SectionGapTitle>{context.title}</SectionGapTitle>
            <BoxIcons>
              {context.tools.map((point, idx) => (
                <Figure key={idx}>
                  <a href={point.link} target="_blank" rel="noopener noreferrer"> <FigureImg src={point.image} alt={point.name}/> </a>
                  <figcaption >{point.name}</figcaption>
                </Figure>
              ))}
            </BoxIcons>
          </Box>
        ))}
        </SectionGap>
      </ColLG12>
    </Section>
    
  )
const About = () => {
  const {t} = useTranslation('global');

  return (
    <AboutPage>
      <AboutIntro t={t}/>
      <ExperienceSection t={t}/>
      <EducationSection  t={t}/>
      <CertificatesSection t={t}/>
      <ProjectsSection t={t}/>
      <SkillsSection t={t}/>
      <Footer/>
    </AboutPage>
  )
}

export default About